const theme = {
    background: "#003262", // dark blue
    backgroundAlt: "#fff", // white
    primary: "#fdb515", // yellow
    secondary: "#fff", // white
    title: "'Bungee', 'Roboto', sans-serif",
    text: "'Bai Jamjuree', 'Roboto', sans-serif",
};

export default theme;
